.App {
  text-align: center;
}
body {
  background-color: #000;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  display: block;
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 550px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.purchase-container {
  border: 1px solid #d3bd0e;
  padding: 5% 0px;
}

.button-lime{
  background-color: #d3bd0e;
}

.btn:disabled{
  background-color: #d3bd0e;
  border-color: #d3bd0e;
}

.btn:hover{
  background-color: #d3bd0e;
  border-color: #d3bd0e;
}

.btn{
  background-color: #d3bd0e;
  border-color: #d3bd0e;
}

w3m-connect-button {
  background-color: #d3bd0e !important;
}

button:hover{
  background-color: #ebd10f;
}

.p-20{
  padding: 0 20px;
}

.button-lime-hover:hover{
  background-color: #ebd10f;
}

.nav-color-lime{
  color: #d3bd0e !important;
}

.color-lime{
color: #d3bd0e;
}

.bg-black{
  background-color: #000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.welcome-text{
  color: #fff;
  font-size: 2rem;
}
.current-text{
  color: #fff;
  font-size: 16px;
}
.form-width {
  width: 330px;
  margin: 0 auto;
}

.form-width input {
  width: 100%;
  border: 1px solid #666;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  color: #000;
  font-size: 1rem;
  outline: none;
}
.divider-border{
  width: 100%;
  height: 4px;
  background-color: #D3BD0E; 
}
.developer-text{
  color: #D3BD0E;
  font-size: 2rem;
}
.order-list li{
  color: #ffff;
  font-size: 16px;
}
.reserved-text{
  color: #abb8c3;
  font-size: 14px;
}
.nav-link{
  font-size: 18px;
    font-weight: 600;
    color: #fff;
    padding: 12px 20px;
    position: relative;
    margin: 0 6px 0 0;
}

#navbarBtn{
  border:none;
}

#navbarBtn:hover{
  border:none;
}

#navbarBtn:active{
  border:none;
}

#navbarBtn:focus #navbarBtn:target #navbarBtn:visited{
  border:none;
}

.tabs-link::before{
  content: "";
  position: absolute;
  bottom: 0;
  width: 0%;
  height: 3px;
  background-color: #fff;
  left: 0;
  right: 0;
  border-radius: 2px;
  margin: 0 auto;
  transition: all .2s linear;
}
.tabs-link:hover::before{
  width: 100%;
  transition: all .2s linear;

}
.tabs-link.active::before{
  width: 100%;
  transition: all .2s linear;

}
.nav-link.active{
  color: #fff !important;
}
.nav-link:hover{
  color: #d3bd0e !important;
}
.nav-link:focus{
  color: #fff !important;
}
.metaModal .modal-content{
  background: rgb(0, 25 ,38) !important;
}
.metaModal .modal-header{
  border-bottom: 0;
}
.metaModal .modal-header .h4{
  Color: #fff !important;
  font-size: 24px;
  margin-bottom: 0;
}

.metaModal .modal-body .h4{
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

.copyIcon{
  position: absolute;
  top: 7px;
  right: 14px;
  cursor: pointer;
  z-index: 11;
  background: #fff;
  padding: 0 5px;
}

.metaModal .modal-body .walletLogo{
  width: 100px !important;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 24px;
  cursor: pointer;
}

.metaModal .modal-body .walletLogo img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.metaModal {    
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.disabled {
  opacity: .5;
  cursor: default;
  pointer-events: none;
}
.button-width{
  width: 330px;
  /* column-count: 2; */
}
.button-width a{
  width: 100%;
}

/* iframe {
  display: none;
} */

.kycInputs{
  width: 100%;
  display: flex;
  gap: 16px;
}

.kycInputs .inputMain {
  width: 33.33%;
  height: 300px;
  border: 2px dashed #ccc;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.kycInputs .inputMain input[type="file"]{
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.kycInputs .preview-image{
  width: 100%;
  height: 100%;
  border-radius:4px;
}

.kycInputs .preview-image img{
  width: 100%;
  height: 100%;
  border-radius:4px;
}

.preview-image .crossIcon{
  position: absolute;
  top: 4px;
  right: 8px;
  cursor: pointer;
}

.img-table{
  width:100px;
  height:100px;
}

.img-table img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modal-img{
  width:300px;
  height:200px;
  margin:0 auto;
}

.modal-img img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#payment-form {
  border: #d3bd0e solid 1px;
  border-radius: var(--radius);
  padding: 20px;
  margin: auto;
  box-shadow: 0 30px 50px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
}

.Label{
  color: #d3bd0e !important;
}

#messages {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
  display: none; /* hide initially, then show once the first message arrives */
  background-color: #0A253C;
  color: #00D924;
  padding: 20px;
  margin: 20px 0;
  border-radius: var(--radius);
  font-size:0.7em;
}

.agree .text-danger{
  position: absolute !important;
  margin-top: 4% !important;
}

.navbar-toggler:hover {
  background-color: transparent;
}


@media (max-width:480px){
  .tabs-link::before {
    height: 0;
  }
}

@media (max-width:960px){
  .tabs-link::before {
    height: 0;
  }
}



